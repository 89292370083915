//
// _layouts-rtl.scss
//


.navbar-menu {
    .navbar-nav {
        .nav-link {
            .badge {
                margin-right: auto;
                margin-left: -2px;
            }
        }

        .nav-link[data-bs-toggle=collapse] {
            &:after {
                left: 18px;
                right: auto;
            }
        }

        .nav-sm {
            padding-right: 1.75rem;
            padding-left: 0px;

            .nav-link {
                &:before {
                    right: 2px;
                    left: 0px;
                }
            }

            .nav-sm {
                padding-right: 15px;
                padding-left: 0px;
            }
        }
    }
}

[data-layout="horizontal"] {
    .navbar-menu {
        .navbar-nav {
            .nav-link[data-bs-toggle=collapse] {
                &:after {
                    margin-right: auto;
                    left: 18px;
                    right: auto;
                    transform: rotate(179deg);
                }
            }
        }
    }
}


.vertical-overlay {
    right: 0;
}

// Customizer 
.customizer-setting {
    left: 20px;
    right: auto;
}

#back-to-top {
    left: 28px;
    right: auto;
}


//Topbar
.navbar-header {
    padding: 0 calc(#{$grid-gutter-width} / 2) 0 $grid-gutter-width;
}

.app-search {
    padding: calc(#{$header-height - 38px} / 2) 0;

    span.search-widget-icon {
        right: 13px;
        left: auto;
    }

    .form-control {
        padding-right: 40px;
        padding-left: 30px;
    }

    .search-widget-icon-close {
        left: 7px;
        right: auto;
    }
}

.topbar-badge-sm {
    left: 0;
}

.topbar-badge {
    right: -9px;
    left: auto;
}

.navbar-nav {
    padding-right: 0;
}

//Horizontal 

[data-layout=horizontal] {
    #page-topbar {
        right: 0;
    }

    .page-content {
        padding: calc(45px + 1.5rem) calc(1.5rem / 2) 60px calc(1.5rem / 2);
    }

    .footer {
        right: 0 !important;
    }

    .main-content {
        margin-right: 0;
    }

    .horizontal-logo {
        padding-right: calc(1.5rem / 2);
    }

    .navbar-menu {
        padding: 0 calc(1.5rem / 2);

        .navbar-nav {
            .nav-sm {
                padding-right: 0;
            }

            >.nav-item {
                >.nav-link[data-bs-toggle=collapse] {
                    &:after {
                        left: 0;
                        right: auto;
                    }
                }
            }

            >li {
                &:nth-of-type(2) {
                    >.nav-link.menu-link {
                        padding-right: 0;
                        padding-left: $horizontal-menu-item-padding-x;
                    }
                }
            }
        }
    }

    .navbar-nav {
        .nav-item {
            .nav-link[data-bs-toggle=collapse] {
                &:after {
                    left: 10px;
                }
            }
        }
    }

    .menu-dropdown {
        .menu-dropdown {
            top: 0;
            right: 100%;
        }
    }

    .dropdown-custom-right {
        right: -100% !important;
        left: 100%;
    }

    &[data-layout-width=boxed] {
        .navbar-header {
            padding: 0 0 0 calc(1.5rem / 2);
        }

        &[data-sidebar-size=lg] {
            #page-topbar {
                right: 0 !important;
            }

            .footer {
                right: 0 !important;
            }
        }

        &[data-sidebar-size=sm-hover] {
            #page-topbar {
                right: 0 !important;
            }

            .footer {
                right: 0 !important;
            }
        }

        &[data-sidebar-size=sm-hover-active],
        &[data-sidebar-size=sm] {
            #page-topbar {
                right: 0 !important;
            }

            .footer {
                right: 0 !important;
            }
        }
    }
}

// Two Column Layouts 
[data-layout=twocolumn] {

    #page-topbar {
        right: calc(#{$twocolumn-menu-iconview-width} + #{$twocolumn-menu-width});
        left: 0px;
    }

    .app-menu {
        right: 70px;
        left: 0px;

        .navbar-nav {
            height: calc(100vh - 70px);
        }
    }

    .main-content {
        margin-right: calc(#{$twocolumn-menu-width} + #{$twocolumn-menu-iconview-width});
        margin-left: 0px;
    }

    .twocolumn-iconview {
        right: -70px;
    }

    .twocolumn-panel {
        .main-content {
            margin-right: $twocolumn-menu-iconview-width;
            margin-left: 0px;
        }

        .footer,
        #page-topbar {
            right: $twocolumn-menu-iconview-width;
            left: 0px;
        }
    }

    @media (min-width:768.1px) {
        .footer {
            right: calc(#{$twocolumn-menu-iconview-width} + #{$twocolumn-menu-width});
            left: 0px;
        }
    }
}

// Footer 
.footer {
    left: 0;
    right: $vertical-menu-width;
}

.auth-page-wrapper {
    .footer {
        right: 0;
    }
}

// Vertical Layouts 
[data-layout=vertical] {
    &[data-sidebar-size=sm-hover] {
        .footer {
            right: 70px;
            left: 0px;
        }

        .main-content {
            margin-right: $vertical-menu-width-sm;
            margin-left: 0px;
        }

        .navbar-menu {

            .navbar-nav {

                .nav-link {
                    svg {
                        margin-left: 0;
                    }
                }
            }

            &:hover {
                .nav-link {
                    svg {
                        margin-left: .665rem;
                    }
                }

                .menu-title {
                    text-align: right;
                }
            }
        }
    }

    &[data-sidebar-size=sm-hover],
    &[data-sidebar-size=sm-hover-active] {
        .navbar-brand-box {
            text-align: right;
        }
    }

    &[data-sidebar-size=sm] {
        .footer {
            right: 70px;
            left: 0px;
        }

        .navbar-menu {
            .navbar-nav {
                .nav-item {

                    &:hover {
                        >.menu-dropdown {
                            right: 70px;
                            left: auto;
                            border-radius: 3px 3px 0 0;
                        }
                    }
                }

                .nav-sm {
                    .menu-dropdown {
                        right: 100% !important;
                    }
                }
            }
        }
    }

    &[data-sidebar-size=md] {
        .footer {
            right: 180px;
            left: 0px;
        }

        .navbar-menu {
            .navbar-nav {
                .nav-sm {
                    padding-right: 0;
                }
            }
        }
    }

    &[data-sidebar="dark"] {
        .navbar-menu {
            border-left: 1px solid $vertical-menu-border-dark;
            border-right: none;
        }
    }
}

// Boxed Layouts
[data-layout-width=boxed] {

    #page-topbar {
        right: 0 !important;
    }

    .footer {
        right: 0 !important;
    }

    &[data-sidebar-size=sm-hover] {
        #page-topbar {
            right: 0 !important;
        }

        .footer {
            right: 0 !important;
        }
    }

    &[data-sidebar-size=sm] {
        #page-topbar {
            right: 0 !important;
        }

        .footer {
            right: 0 !important;
        }
    }

    &[data-layout=vertical] {
        &[data-layout-style=detached] {
            &[data-sidebar-size=lg] {
                #page-topbar {
                    right: 0 !important;
                }

                .footer {
                    right: 0 !important;
                }
            }

            &[data-sidebar-size=md] {
                #page-topbar {
                    right: 0 !important;
                }

                .footer {
                    right: 0 !important;
                }
            }

            &[data-sidebar-size=sm-hover] {
                #page-topbar {
                    right: 0 !important;
                }

                .footer {
                    right: 0 !important;
                }
            }

            &[data-sidebar-size=sm] {
                #page-topbar {
                    right: 0 !important;
                }

                .footer {
                    right: 0 !important;
                }
            }
        }
    }
}


@media (min-width:768px) {
    #page-topbar {
        right: 250px;
        left: 0px;
    }

    [data-layout=vertical] {
        &[data-sidebar-size=sm-hover] {
            #page-topbar {
                right: 70px;
                left: 0px;
            }
        }

        &[data-sidebar-size=sm] {
            #page-topbar {
                right: 70px;
                left: 0;
            }

            .main-content {
                margin-right: $vertical-menu-width-sm;
                margin-left: 0px;
            }
        }

        &[data-sidebar-size=md] {
            #page-topbar {
                right: 180px;
                left: 0px;
            }

            .main-content {
                margin-right: 180px;
                margin-left: 0px;
            }
        }
    }

    .main-content {
        margin-right: 250px;
        margin-left: 0px;
    }

    [data-layout-width=boxed] {
        &[data-sidebar-size=sm-hover] {
            #page-topbar {
                right: 70px !important;
                left: 0px !important;
            }

            .footer {
                right: 70px !important;
                left: 0 !important;
            }
        }

        &[data-sidebar-size=sm] {
            #page-topbar {
                right: 70px !important;
                left: 0px !important;
            }

            .footer {
                right: 70px !important;
                left: 0px !important;
            }
        }

        &[data-sidebar-size=lg] {
            #page-topbar {
                right: 250px !important;
                left: 0px !important;
            }

            .footer {
                right: 250px !important;
                left: 0px !important;
            }
        }

        &[data-sidebar-size=sm-hover-active] {
            #page-topbar {
                right: 250px !important;
                left: 0px !important;
            }

            .footer {
                right: 250px !important;
                left: 0px !important;
            }
        }

        &[data-sidebar-size=md] {
            #page-topbar {
                right: 180px !important;
                left: 0px !important;
            }

            .footer {
                right: 180px !important;
                left: 0px !important;
            }
        }
    }
}

@media (min-width: 1024.99px) {
    [data-layout="vertical"][data-sidebar-size="sm-hover"] .navbar-menu:hover .navbar-brand-box {
        text-align: right;
    }
}


@media (max-width:767.98px) {

    [data-layout=vertical] {
        .app-menu {
            margin-right: -100%;
        }

        &[data-sidebar-size=sm-hover] {
            .footer {
                right: 0;
            }

            .main-content {
                margin-right: 0;
            }
        }

        &[data-sidebar-size=sm] {
            .footer {
                right: 0;
            }

            .app-menu {
                margin-right: 0;
            }
        }
    }

    [data-layout=twocolumn] {

        .main-content {
            margin-right: 70px;
        }

        #page-topbar {
            right: 70px;
        }

        .footer {
            right: 70px;
        }
    }

    [data-layout-width=boxed][data-sidebar-size=sm-hover][data-layout=vertical] {
        .main-content {
            margin-right: 0;
        }
    }

    [data-layout-width=boxed][data-sidebar-size=sm][data-layout=vertical] {
        .main-content {
            margin-right: 0;
        }
    }
}

@media (max-width:1023.99px) {
    .app-search {
        padding-right: calc(1.5rem / 2);
    }
}

[data-layout="horizontal"],
[data-layout-style=detached] {
    @media (min-width: 1700px) {
        .layout-rightside {
            margin-left: 0;
        }
    }
}


@media (min-width:1024.1px) {
    [data-layout=vertical] {
        &[data-layout-style=detached] {
            #page-topbar {
                right: 0 !important;
            }

            .horizontal-logo {
                padding-right: 0;
                padding-left: 1.3rem
            }

            .footer {
                right: 0 !important;
            }

            #layout-wrapper {
                padding-right: $grid-gutter-width;
                padding-left: 0px;
            }

            .navbar-header {
                padding-right: $grid-gutter-width;
            }

            .navbar-menu {
                border-left: var(--vz-vertical-menu-bg);
            }

            &[data-sidebar-size=sm-hover] {
                .navbar-brand-box {
                    text-align: right;
                }
            }

            &[data-sidebar-size=sm] {
                .navbar-brand-box {
                    text-align: right;
                }
            }
        }
    }
}

@media (max-width:991.98px) {
    .footer {
        right: 0;
    }

    [data-layout=vertical] {
        &[data-sidebar-size=md] {
            .footer {
                right: 0;
            }
        }
    }
}

@media (min-width:1025px) {
    [data-layout=vertical][data-sidebar-size=sm-hover-active] {
        .navbar-header {
            padding-right: 1.5rem;
        }
    }

    [data-layout=vertical][data-sidebar-size=sm-hover] {
        .navbar-header {
            padding-right: 1.5rem;
        }
    }
}

@media (max-width:1024.98px) {
    [data-layout=horizontal] {
        .horizontal-logo {
            padding-right: 1.5rem;
        }
    }
}

@media (max-width:575.98px) {

    [data-layout=twocolumn] {
        .twocolumn-panel {

            .main-content {
                margin-right: 0;
            }

            #page-topbar {
                right: 0;
            }

            .footer {
                right: 0;
            }
        }

        .main-content {
            margin-right: 0;
        }

        #page-topbar {
            right: 0;
        }

        .footer {
            right: 0;
        }
    }
}

@media (max-width:1024px) {
    [data-layout=horizontal] {
        .menu {
            .navbar-menu {
                padding-right: 0;

                .navbar-nav {
                    >li {
                        &:nth-of-type(2) {
                            >.nav-link.menu-link {
                                padding-right: 1.5rem;
                            }
                        }
                    }
                }
            }

            .menu-dropdown {
                padding-right: 28px;
                right: 0;
            }

            .dropdown-custom-right {
                right: 0 !important;
            }

            .nav-item {
                .nav-link[data-bs-toggle=collapse] {
                    &:after {
                        left: 0;
                    }
                }
            }
        }
    }
}


// [dir=rtl] .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
//     transform: rotate(-180deg)
// }

// [dir=rtl] .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after {
//     transform: rotate(-270deg)
// }