.ftco-section {
  padding: 3em 0;
}

.ftco-no-pt {
  padding-top: 0;
}

.ftco-no-pb {
  padding-bottom: 0;
}

.heading-section {
  font-size: 28px;
  color: #000;
}

.wrapper {
  width: 100%;
  -webkit-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
}

.contact-wrap {
  background: #fff;
}

.info-wrap {
  color: rgba(255, 255, 255, 0.8);
}
.info-wrap h3 {
  color: #fff;
}
.info-wrap .dbox {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
}
.info-wrap .dbox:last-child {
  margin-bottom: 0;
}
.info-wrap .dbox p {
  margin-bottom: 0;
}
.info-wrap .dbox p span {
  font-weight: 500;
  color: #fff;
}
.info-wrap .dbox p a {
  color: #fff;
}
.info-wrap .dbox .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
}
.info-wrap .dbox .icon span {
  font-size: 20px;
  color: #fff;
}
.info-wrap .dbox .text {
  width: calc(100% - 50px);
}
